var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.filteredRecords,"dense":"","items-per-page":15,"footer-props":{'items-per-page-options': [15, 30, 60]},"hide-default-footer":!_vm.records.length},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-3 hidden-sm-and-down",staticStyle:{"max-width":"240px"},attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","hide-details":"","flat":"","single-line":"","clearable":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-select',{staticClass:"mr-3 hidden-sm-and-down",staticStyle:{"max-width":"270px"},attrs:{"items":_vm.roles,"placeholder":"Roles","item-text":"name","return-object":false,"menu-props":{ bottom: true, offsetY: true },"multiple":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 1)?_c('span',[_vm._v(_vm._s(index === 0 ? item.name : (", " + (item.name))))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text ml-2"},[_vm._v(" ( +"+_vm._s(_vm.table.filter.length - 1)+" more ) ")]):_vm._e()]}}]),model:{value:(_vm.table.filter),callback:function ($$v) {_vm.$set(_vm.table, "filter", $$v)},expression:"table.filter"}}),_c('v-spacer'),(_vm.$hasRole(_vm.person.kind.toLowerCase(), 'E'))?_c('v-btn',{staticClass:"mx-1",attrs:{"x-large":"","icon":"","tile":"","color":"accent"},on:{"click":_vm.downloadXLS}},[_c('v-icon',{attrs:{"size":"44"}},[_vm._v(" mdi-database-export ")])],1):_vm._e(),(_vm.$hasRole(_vm.person.kind.toLowerCase(), 'I'))?_c('v-btn',{staticClass:"mx-1",attrs:{"x-large":"","icon":"","tile":"","color":"accent","loading":_vm.$store.state.process === 'importPerson'},on:{"click":function($event){return _vm.$refs.xlsxImport.click()}}},[_c('v-icon',{attrs:{"size":"44"}},[_vm._v(" mdi-database-import ")]),_c('input',{ref:"xlsxImport",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","alt":"xlsxImport"},on:{"change":_vm.uploadXLS}})],1):_vm._e(),(_vm.$hasRole(_vm.person.kind.toLowerCase(), 'C'))?_c('v-btn',{staticClass:"mx-1",attrs:{"x-large":"","icon":"","tile":"","color":"secondary"},on:{"click":function($event){_vm.person.id = 0}}},[_c('v-icon',{attrs:{"size":"48"}},[_vm._v(" mdi-account-plus ")])],1):_vm._e()],1),_c('v-divider')]},proxy:true},{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"130px"}},[_vm._v(" "+_vm._s(_vm.zoneText(item.zone))+" ")])]}},{key:"item.Face",fn:function(ref){
var item = ref.item;
return [(item.Face)?_c('v-avatar',{attrs:{"size":"24"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.setUrlFromImage(item.Face),"alt":"Face"},on:{"error":function () { item.Face = '' }}})]):_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" mdi-account-circle ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$hasRole(item.kind.toLowerCase(), 'RU'))?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.modifyRecord(item)}}},[_vm._v(" "+_vm._s(_vm.$hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye')+" ")]):_vm._e(),(_vm.$hasRole(item.kind.toLowerCase(), 'D'))?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.removeRecord(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-5",staticStyle:{"font-size":"1.15rem"}},[_vm._v(" No data available ")])]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"max-width":"330px"},model:{value:(_vm.dialog.del),callback:function ($$v) {_vm.$set(_vm.dialog, "del", $$v)},expression:"dialog.del"}},[_c('v-card',[_c('v-list-item',{staticClass:"grey lighten-2 px-6 py-2",attrs:{"two-line":""}},[_c('v-list-item-content',{attrs:{"dark":""}},[_c('v-list-item-title',[_vm._v(_vm._s(("Delete item " + (_vm.record.name) + "?")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.record.identity))])],1)],1),_c('v-card-actions',{staticClass:"px-6 py-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"color":"grey darken-2","text":""},on:{"click":function($event){_vm.dialog.del = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"px-4",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.deleteRecord}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         >
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
                  />
               <v-select v-model="table.filter" :items="roles" placeholder="Roles"
                  item-text="name"
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }" multiple hide-details clearable
                  class="mr-3 hidden-sm-and-down" style="max-width: 270px;"
                  >
                  <template v-slot:selection="{ item, index }">
                     <span v-if="index < 1">{{ index === 0 ? item.name : `, ${item.name}` }}</span>
                     <span v-if="index === 1" class="grey--text ml-2">
                        ( +{{ table.filter.length - 1 }} more )
                     </span>
                  </template>
               </v-select>
               <v-spacer />
               <v-btn v-if="$hasRole(person.kind.toLowerCase(), 'E')" x-large icon tile color="accent" class="mx-1" @click="downloadXLS">
                  <v-icon size="44">
                     mdi-database-export
                  </v-icon>
               </v-btn>
               <v-btn v-if="$hasRole(person.kind.toLowerCase(), 'I')" x-large icon tile color="accent" class="mx-1" :loading="$store.state.process === 'importPerson'" @click="$refs.xlsxImport.click()">
                  <v-icon size="44">
                     mdi-database-import
                  </v-icon>
                  <input ref="xlsxImport" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" alt="xlsxImport" style="display:none;" @change="uploadXLS">
               </v-btn>
               <v-btn v-if="$hasRole(person.kind.toLowerCase(), 'C')" x-large icon tile color="secondary" class="mx-1" @click="person.id = 0">
                  <v-icon size="48">
                     mdi-account-plus
                  </v-icon>
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.zone`]="{ item }">
            <div class="text-truncate" style="max-width: 130px;">
               {{ zoneText(item.zone) }}
            </div>
         </template>
         <template v-slot:[`item.Face`]="{ item }">
            <v-avatar v-if="item.Face" size="24">
               <img :src="setUrlFromImage(item.Face)" alt="Face" style="object-fit: cover;" @error="() => { item.Face = '' }">
            </v-avatar>
            <v-icon v-else size="26">
               mdi-account-circle
            </v-icon>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.name}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { imageFromUrl, pastel, decrypt } from '@/utils/nirtara'
import { member } from '@/utils/fields.js'
export default {
   name: 'NMemberTable',

   props: {
      person: { type: Object },
      records: { type: Array },
      imports: { type: Function },
      roles: { type: Array },
      zones: { type: Array },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'ID', value: 'identity', width: 115 },
         { text: 'Name', value: 'name' },
         { text: 'Phone', value: 'phone', width: 150 },
         { text: 'Email', value: 'email' }, //, width: 245
         { text: 'Role', value: 'role' },
         { text: 'Code', value: 'code', width: 115 },
         { text: 'Management', value: 'zone' },
         { text: 'Face', value: 'Face', width: 30, align: 'center pr-0', sortable: false },
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            var filteredPhone = j.phone && j.phone.toLowerCase().indexOf(search) !== -1
            return (!search || filteredName || filteredPhone) &&
                   (!this.table.filter.length || this.table.filter.includes(j.role))
         })
      },
      zoneText () {
         return zone => (this.zones.find(j => j.zone === zone) || { name: zone === '*' ? 'All Management' : zone }).name
      },
   },
   created () {
      this.$store.state.user.code !== 'SPA' && this.columns.splice(this.columns.findIndex(j => j.value === 'zone'), 1)
   },
   methods: {
      uploadXLS () {
         const reader = new FileReader()
         reader.onload = async e => {
            const workbook = await new this.$excelJS.Workbook().xlsx.load(reader.result)
            const rows = []
            workbook.eachSheet(worksheet => {
               if (worksheet.name !== 'Member') return
               worksheet.eachRow((row, idx) => {
                  if (idx === 1) return
                  const object = {
                     Member: [],
                     name: String(row.values[2] || ''),
                     phone: String(row.values[3] || ''),
                     email: String(row.values[4] || ''),
                     password: String(row.values[5] || ''),
                     role: String(row.values[6] || ''),
                     code: String(row.values[7] || ''),
                     status: String(row.values[8] || ''),
                  }
                  const array = []
                  row.values[1] && row.values[2] && rows.push({
                     kind: worksheet.name,
                     identity: String(row.values[1] || ''),
                     site: this.$site,
                     array: array,
                     object: object,
                  })
               })
            })
            // console.log(`uploadXLS (${rows.length}):`, JSON.parse(JSON.stringify(rows)))
            this.imports(rows, len => {
               this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${len} members imported successfully` }], kind: 'Success' })
            })
         }
         reader.readAsArrayBuffer(this.$refs[event.target.alt].files[0])
      },
      async downloadXLS () {
         const workbook = new this.$excelJS.Workbook()
         workbook.creator = 'NIRTARA Visitor Management'
         const worksheet = workbook.addWorksheet(this.person.kind)

         const column = { identity: 'Code', name: 'Name', phone: 'Phone', email: 'Email', password: 'Password', role: 'Role', code: 'Code', status: 'Status' }
         worksheet.columns = Object.keys(column).map(s => ({ header: column[s], key: s }))
         worksheet.getRow(1).eachCell(cell => { cell.style = { font: { bold: true, size: 12 } } })
         let keys = []
         this.filteredRecords.map(j => { worksheet.addRow(j); keys.push(j.role) })

         this.$excelJS.autoWidth(worksheet)

         keys = keys.filter((value, index, self) => self.indexOf(value) === index)
         const background = pastel(1 + keys.length)
         const rules = keys.map((t, i) => ({
            type: 'expression',
            formulae: [`$G2="${t}"`],
            style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: background[i] } } },
         }))
         worksheet.addConditionalFormatting({ ref: `A2:${this.$excelJS.numberToLetters(Object.keys(column).length - 1)}${1 + this.filteredRecords.length}`, rules: rules })

         const saveAs = require('file-saver')
         const buffer = await workbook.xlsx.writeBuffer()
         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
         saveAs(blob, `NVM-50-WEB-${this.$moment().format('YYYYMMDD-HHmmss')}.xlsx`)
      },

      modifyRecord (item) {
         Object.assign(this.person, member, item, { password: decrypt(item.password || '') })
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
</style>
